.photos_download_events {
  margin: 20px 0;

  &__text_edit {
    font-size: 14px;
    font-weight: 400;
    color: #4C5B78;
  }

  &__DragYourPhoto {
    width: 100%;
    height: 277px;
    background-color: #F3F6FF;
    border: 2px dashed #D7DFFE;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
       .block-icons {
         display: flex;
         justify-content: center;
       }

      h2 {
        font-size: 18px;
        font-weight: 700;
        padding-top: 12px;
        text-align: center;
      }
      div {
        display: flex;
        justify-content: center;
        .btn_file {
          cursor: pointer;
          width: 137px;
          height: 40px;
          display: flex;
          align-items: center;
          color: #007AFF;
          font-weight: 500;
          border-radius: 8px;
          background-color: rgba(0, 122, 255, 0.1);
          justify-content: center;
          #upload-photo {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }
        }
      }


    }
  }

  &__save_photos_down2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 460px;
    > div {
      padding-top: 10px;
      .btn_file{
        cursor: pointer;
        width: 142px;
        height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        border-radius: 8px;
        background-color: rgba(0, 122, 255, 0.1);
        > div {
          .icons_file {
            display: flex;
            justify-content: center;
            padding-top: 10px;
          }
        }
        #upload-photo {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
    }

}

.flex_drag {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background-color:  rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    text-align: center;
  }
}

.image_download {
  display: flex;
  justify-content: right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 142px;
  height: 88px;
  padding-right: 4px;
  padding-top: 4px;
  border-radius: 8px;
  > p {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EB4335;
    border-radius: 4px;
    cursor: pointer;
  }
}

.text_select_none {
  webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
  font-size: 14px;
}
