.SignIn {
  background: #fff;
  height: 100vh;
  overflow-y: hidden;

  @media (max-width: 990px) {
    overflow-y: auto;
  }

  &__username {
    border-radius: 8px;
    height: 38px;
  }
}

.mainColumnStyles {
  margin-top: 130px;
}

.mainImageStyles {
  position: absolute;
  top: 0;
  left: 0;
}

.submainStyles {
  margin-bottom: 1.6rem !important;
}

.linkStyle {
  position: absolute;
  right: 0;
  z-index: 100;
  margin-top: -24px;
}

.buttonStyle {
  margin: 30px 0;
}

.ant-message-notice .custom-class {
  border-radius: 8px;
  background: #FF4B55;
}

.googleStyles {
  margin-right: 8px;
  width: 17px;
}

.Toastify__toast--error {
  width: 400px;
  border: 1px solid #FF4B55;
  border-radius: 8px !important;
  background: #FF4B55 !important;
  color: #fff;
}

.Toastify__toast--error div {
  color: #fff;
}

.Toastify__toast--error button {
  margin-top: 14px;
}

.messageStyles {
  margin-right: 840px;
}

.messageStyles div {
  background: #FF4B55;
  border-radius: 8px;
  color: #fff;
  padding: 4px 6px;
}

.lockStyles {
  width: 21px;
  margin: 0 8px 5px 0;
}
