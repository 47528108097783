.CustomizeRenderEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  &__title {
    margin-bottom: 25px;
    font-size: 35px;
    color: #b0b0b0;
    text-transform: initial;
  }
}
