.NotFoundSearch {
  padding-top: 10px;
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #202226;
    margin-top: -20px;
  }

  &__text {
    font-weight: 400;
    color: #7A818E;
    font-size: 12px;
  }
}