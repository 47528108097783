.text_page1 {
  color: #455470;
  font-size: 14px;
  font-weight: 400;
}

.text_page2 {
  color: #007AFF;
  font-size: 14px;
  font-weight: 400;
}

.text_page3 {
  color: #99A1AE;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  max-width: 380px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}