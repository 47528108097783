.block_card_flex {
  display: flex;
}

.Conditions {
  width: 512px;
  background-color: $bg-white;
  border-radius: $border-radius;
  padding: 32px 24px 2px 24px;

  &__title {
    font-size: 22px;
    color: #212C41;
    font-weight: 600;
    padding-top: 8px;
  }

  &__text {
    font-size: 14px;
    color: #4C5B78;
    font-weight: 400;
    margin-top: -20px;
  }

  &__button {
    display: flex;
    justify-content: space-between;
  }

  &__AddNewConditions {
    margin-top: 16px;
  }

  &__block_btn {
    width: 512px;
    height: 60px;
    background-color: $bg-white;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    margin-top: 32px;
  }

  &__text_page {
    line-height: 1.3;
    text-align: center;
    .text_page_title {
      font-weight: 600;
      color: #212C41;
    }

    .text_page_text {
      font-size: 13px;
      font-weight: 400;
      color: #97A3BA;
      padding-left: 3px;
    }
  }
}

.ConditionsForm {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding-top: 6px;
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
  }

  .ant-form-vertical .ant-form-item-label>label {
    font-size: 14px;
    font-weight: 400;
    color: #67697F;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  .ant-input-number-input {
    padding-top: 6px;
  }

  .ant-input:placeholder-shown {
    font-size: 14px;
    font-weight: 400;
    color: #67697F;
  }

  .ant-input {
    font-size: 14px;
    font-weight: 400;
    color: #67697F;
  }

  &__button {
    display: flex;
    justify-content: space-between;
  }

  &__switch {
    width: 170px;
    display: flex;
    justify-content: flex-end;
  }

  &__text_switch {
    margin-top: -20px;
    font-size: 12px;
    font-weight: 400;
    color: #949FB3;
  }

  &__form {
    display: flex;
    align-items: flex-end;
  }

}

.ant-input-number-input {
  padding-top: 6px;
}

.block_form_percent {
  display: flex;
  justify-content: space-between;
}

.form_block {
  width: 512px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
  > p {
    font-weight: 400;
    font-size: 14px;
  }

  > div {
    cursor: pointer;
  }
}

.ant-input-number-input{
  padding: 7px 10px 0 12px !important;
}

.ant-popconfirm-buttons {
  text-align: center !important;
}
