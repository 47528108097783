.ButtonsHandlers {

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 2px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #47CE6D;
    height: 44px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
  }

  .ant-select-arrow {
    color: $color-white;
    margin-top: -5px;
  }

  > div {
    margin-bottom: 12px;
  }

  > button {
    margin-bottom: 12px;
    display: block;
  }
}

.paddingRight {
  padding-right: 5px;
}

.delete_btn {
  border: none;
}

.delete_btn:hover {
  background-color: red !important;
  color: white !important;
}
