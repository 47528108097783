.AppRouter {
  display: flex;
  background: $bg-body;
  &__routers {
    width: 100%;

    .route_components {
      padding: 32px;
      margin-top: 72px;
      margin-left: 260px;

      @media (max-width: 820px) {
        margin-left: 73px;
      }

    }
  }
}