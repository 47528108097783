.block_flex {
  display: flex;
  flex-wrap: wrap;
}

.StatisticItemComponent {
  margin-top: 16px;
  &__block_static {
    position: relative;
    background-color: white;
    width: 260px;
    height: 120px;
    border-radius: 8px;
    padding: 20px;
    > p {
      font-weight: inherit;
      color: #130E2F;
      font-size: 14px;
      line-height: 1.2;
    }

    .text_static {
      line-height: 0.8;
      position: absolute;
      bottom: 11px;
      margin-bottom: 15px;
      > p {
        font-size: 11px;
        color: #130E2F;
        font-weight: 400;
      }

      .summa {
        display: flex;
        align-items: center;
        .text_sum {
          font-size: 18px;
          font-weight: 600;
        }

        .interest {
          font-size: 12px;
          color: #47CE6D;
          padding-left: 8px;
        }
        .sumRen {
          line-height: 0.2;
          padding-left: 5px;
          > p {
            color: #130E2F;
            font-weight: 200;
            font-size: 10px;
          }

          > div {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .active_block_static {
    position: relative;
    box-shadow: 20px 20px 50px 15px grey;
    z-index: 1001;
  }
}

.ant-drawer-content-wrapper {
  position: fixed !important;
  z-index: 1004 !important;
}
.ant-drawer-mask {
  position: fixed;
}
