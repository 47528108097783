.Challenges {
  padding-top: 10px;
  &__text_challenges {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 6px;

    .status {
      text-align: center;
      margin-top: -5.3px;
      > span {
        font-weight: 400;
        color: #73758C;
        font-size: 14px;
      }
    }
  }

  &__pagination_btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn_Challenges {
      border-radius: $border-radius;
      width: 129px;
    }
  }

  &__block_category {
    display: flex;
    padding-bottom: 10px;
    > div {
      margin-right: 10px;
    }
  }

  &__block_d_flex_categories {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    > div {
      display: flex;
    }
  }
}

.title_text_v {
  font-size: 18px;
  font-weight: 600;
  color: #121427;
  margin-top: -10px;
  margin-bottom: -5px;
}

.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: $border-radius !important;
}

.text_categories_name {
  color: #007AFF;
}
