.active {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #007AFF;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    @include textActiveInactive(11px, #ffffff);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 1px 11px 0;
  }
}

.inactive {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #CEE2FF;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    @include textActiveInactive(11px, #130E2F);
  }
}

.activeOpen {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $bg-activeOpen;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
   @include textActiveInactive(14px, #ffffff);
  }
}

.inactiveOpen {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $bg-inactiveOpen;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    @include textActiveInactive(14px, #ffffff);
  }
}
