.News {
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #767988;
    margin-bottom: 20px;
  }
  &__create {
    width: 200px;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
  }

  &__pagination_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn_starts {
      border-radius: $border-radius;
      width: 129px;
    }
  }
}
