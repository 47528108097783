.TeamsItemComponent {
  background: #ffffff;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  margin-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      margin-top: 0;
    }
  }

  &__title {
    font-size: 20px;
  }

  &__description {
    color: #848484;
  }

  &__number_info {
    display: flex;
    gap: 30px;
    background: #F9F9F9;
    padding: 10px 16px 10px 16px;
    border-radius: 8px;

    .title {
      font-size: 14px;
      color: #848484;
      font-weight: 400;
    }

    .number {
      font-size: 16px;
      font-weight: 500;
    }
  }

}
