.ProgressComponents {

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #FB4400;
  }
  width: 286px;
  background-color: $bg-white;
  padding: 20px;
  margin-left: 31px;
  border-radius: $border-radius;

  &__progress {
    width: 246px;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #E8EBFF;
    border-radius: $border-radius;
  }

  &__text {
    margin-top: 10px;
    .title {
      font-weight: 700;
      font-size: 18px;
      color: #212C41;
    }

    .text_progress {
      font-weight: 400;
      color: #7489B0;
      font-size: 12px;
      margin-top: -14px;
    }
  }

  &__checkMark {

    > div {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      margin-top: 12px;
      .icons_checkMark {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }

      .blockCheckMark_text {
        padding-left: 9px;
        font-weight: 400;
        font-size: 12px;
        color: #130E2F;
      }
    }
  }
  .block_text_size {
    position: relative;
    top: -5px;
    left: 0;
    width: 28px !important;
    height: 15px !important;
  }
  .line_height_block {
    line-height: 1.2;
    font-size: 10px;
  }
}

.block_bottom {
  margin-bottom: -1.5px;
}

.text_confirm {
  text-align: center;
  width: 200px;
  font-weight: 400;
  font-size: 14px;
  color: #8B92A6;
}

.text_warning {
  text-align: center;
}
