.ant-drawer-content {
  background-color: #F7F8FF;;
}

.ant-drawer-body {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.ant-drawer-body::-webkit-scrollbar {
  background-color: #fff;
  border-radius: 8px;
  width: 4px;
  overflow: hidden;
}

.ant-drawer-body::-webkit-scrollbar-thumb
{
  background-color: #007AFF;
  border-radius: 8px;
}

.drawer {

  &__title_drawer {
    line-height: 0.5;
    padding-bottom: 32px;

    h2 {
      font-weight: 700;
      line-height: 1.2;
    }
  }
  &__block_static_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
      font-weight: 400;
      font-size: 16px;
      color: #007AFF;
    }

    > button {
      cursor: pointer;
    }
  }
}

.block_drawer {
  width: 100%;
  height: 97px;
  background-color: white;
  border-radius: 6px;
  padding: 16px;
  line-height: 0.8;
  margin-top: 16px;
  .text_draw_title {
    font-weight: 600;
    font-size: 14px;
  }
  .text_draw_events {
    padding-top: 12px;
    font-weight: 400;
    font-size: 11px;
    color:#130E2F;
    margin-bottom: 8px;
  }

  .text_draw_interest {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    > span {
      font-weight: 400;
      padding-left: 6px;
      color: #47CE6D;
      font-size: 12px;
    }
  }
}