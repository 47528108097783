.UploadPhoto {
  width: 512px;
  background-color: $bg-white;
  border-radius: $border-radius;
  padding: 32px 24px 40px 24px;

  &__title {
    font-size: 22px;
    font-weight: 600;
    color: #212C41;
    padding-top: 10px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #4C5B78;
    margin-top: -15px;
  }

  &__block_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }

  &__text_page {
    line-height: 1.3;
    text-align: center;
    .text_page_title {
      font-weight: 600;
      color: #212C41;
    }

    .text_page_text {
      font-size: 13px;
      font-weight: 400;
      color: #97A3BA;
      padding-left: 3px;
    }
  }
}

.block_photos {
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
