.localizationButtonStyle {
    width: 110px;
    position: absolute;
    z-index: 1000;
    top: 68px;
    right: 50px;
}

.select-option-styles {
    width: 74px !important;
}

.ant-select-selection-item {
    display: flex;
}