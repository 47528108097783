.NotActiveInactive {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -140px;
   &__block {
     width: 382px;

     .block_img {
       display: flex;
       justify-content: center;
     }
   }

  &__block_2 {
    display: flex;
    justify-content: center;

    > div {
      > p {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
      }

      > div {
        display: flex;
        justify-content: center;
      }
    }
  }
}