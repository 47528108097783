.signImg {
    padding: 0;
    position: relative;
}

.ant-carousel .slick-slide img {
    width: 100%;
    height: 100vh;
}

.signImg img {
    margin: auto;
    display: block;
}

.logoStyles {
    position: absolute;
    z-index: 1000;
    top: 47px;
    left: 47px;
    width: 200px;
}

.subtitleStyles {
    color: white !important;
    position: absolute;
    z-index: 1000;
    bottom: 160px;
    left: 44px;
    font-size: 50px !important;
}

.titleStyles {
    width: 500px;
    color: white !important;
    position: absolute;
    z-index: 1000;
    bottom: 88px;
    left: 46px;
}

@media (max-width: 550px) {
    .titleStyles {
        width: 380px;
    }
}
