.ModalFormConditions {
  padding: 20px;
  &__title {
    font-weight: 700;
    font-size: 20px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #4C5B78;
    margin-top: -16px;
    padding-bottom: 13px;
  }

  &__block_card {
    > div {
      cursor: pointer;
      width: 384px;
      height: 87px;
      padding: 12px;
      background-color: #FAFAFF;
      margin-top: 12px;
      border-radius: 8px;
      display: flex;

      .text_card {
        padding-left: 10px;
        .title_card {
          width: 324px;
          display: flex;
          justify-content: space-between;
          .t_pg {
            font-weight: 600;
            font-size: 15px;
          }

          .t_link {
            font-weight: 500;
            font-size: 13px;
            color: #007AFF;
            cursor: pointer;
          }

          .remove_link {
            font-weight: 500;
            font-size: 13px;
            cursor: pointer;
            color: #FF4B55
          ;
          }
        }
        > p {
          display: flex;
          justify-content: left;
          font-weight: 400;
          font-size: 12px;
          color: #6E81A4;
        }
      }
    }
  }
  }

.ant-popover-inner {
  border-radius: 4px;
}

.ant-popover-buttons {
  text-align: center;
  > button {
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #1890ff;
    color: white;
    border: 1px solid #1890ff;
  }

  > button:hover {
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    color: #1890ff;
  }
}

.icons_block_card {
  width: 30px;
  height: 27px;
  display: flex;
  justify-content: center;
  background-color: #ECEBFF;
  border-radius: 8px;
  padding: 3px 5px 0 6px;
}

.block_pop {
  display: flex;
}

.svg_blue {
  filter: brightness(0) saturate(100%) invert(29%) sepia(97%) saturate(2979%) hue-rotate(202deg) brightness(105%) contrast(104%);
}
