//Challenges vs promotions card
@mixin card() {
  padding: 8px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@mixin widthHeightCard() {
  width: 258px;
  height: 268px;
}

@mixin widthHeightCardPromotions() {
  width: 258px !important;
  height: 236px !important;
}

//Active Inactive
@mixin textActiveInactive($size, $color) {
  color: $color;
  font-weight: 600;
  font-size: $size;
}
