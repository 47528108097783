.Registration {
    background: #fff;
}

.username {
    border-radius: 8px;
}

.ant-form-item-label>label {
    font-weight: 600;
}

.ant-select.ant-select-in-form-item {
    width: 100% !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
}

.success-block {
    margin-top: 60px;
}
