.StatusRun {
    background-color: #f7f8ff;
    line-height: 1.3;
    padding: 3px 12px 3px 12px;
    border-radius: 6px;
    margin-right: 8px;
    margin-top: 8px;

    .title {
      font-size: 12px;
      color: #a9aed1;
      font-weight: 200;
    }

    .text {
      font-weight: 400;
      font-size: 12px;
      color: #130e2f;
    }
  }
