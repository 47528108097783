.Info {
  &__promotion-info-style-calc {
    display: flex;
    gap: 20px;
    border-radius: 8px;
  }

  &__promotion-info {
    border-radius: 8px;
    width: 300px;
    height: 40%;
  }

  &__promotion-info-actions {
    border-radius: 8px;
    width: 300px;
    height: 40%;
  }


  @media (max-width: 1100px) {
    &__promotion-info {
      width: 600px;
    }

    &__promotion-info-style-calc {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      border-radius: 8px;
    }

    &__promotion-info-actions {
      width: 50%;
    }
  }

  &__promotion-buttons-actions {
    width: 100%;
    border-radius: 8px;
    height: 40px;
  }

  &__description-style {
    font-size: 14px !important;
    font-weight: 400 !important;
    display: inline;
    > p {
      display: inherit;
    }
  }

  &__images_group {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;

    .images_overflow {
      .block_scroll {
        height: 278px;
        margin-top: 5px;
        overflow: scroll;

        > div {
          margin-bottom: 12px;
        }
      }
    }

    .block_scroll::-webkit-scrollbar {
      width: 0;
    }

    .block_start {
      width: 42px;
      height: 14px;
      background-color: #200E32;
      border-radius: 3px 3px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      right: 44px;
      top: -5px;
      cursor: pointer;
    }

    .block_end {
      width: 42px;
      height: 14px;
      background-color: #200E32;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      bottom: 8px;
      right: 44px;
      cursor: pointer;
    }
  }

  &__modal-style .ant-modal-content {
    border-radius: 8px;
    text-align: center;
  }

  &__modal-buttons {
    display: flex;
    justify-content: space-evenly;
  }

  &__modal-title-h5 {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #130E2F !important;
  }

  &__modal-title-h6 {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #67697F !important;
    margin-bottom: 30px !important;
  }
}

.show-more-styles {
  > p {
    display: inline;
  }
}

.modal-button {
  width: 165px;
}

.modal-button-cancel {
  width: 165px;
  background: #F4F6FF;
  border: 1px solid #F4F6FF;
  color: #007AFF;
}

.status-button-active {
  background: #47CE6D;
  border: 1px solid #47CE6D;
}

.status-button-active:hover {
  background: #93f39c !important;
  border: 1px solid #93f39c !important;
}

.status-button-active:focus {
  background: #47CE6D;
  border: 1px solid #47CE6D;
}

.status-button-inactive {
  background: #FFA800;
  border: 1px solid #FFA800;
}

.status-button-inactive:hover{
  background: #f3d59b !important;
  border: 1px solid #f3d59b !important;
}

.status-button-inactive:focus {
  background: #FFA800;
  border: 1px solid #FFA800;
}

.promotions_delete_modal {
  padding: 30px 30px 15px 30px;
}

.delete-button {
  background: rgba(0, 122, 255, 0.05);
  border: 1px solid rgba(0, 122, 255, 0.05);
  color: #EB4335;
  box-shadow: none;
  text-shadow: none;
}

.delete-button:focus, .delete-button:hover {
  background: #EB4335 !important;
  border: 1px solid #EB4335 !important;
  color: #fff;
}

.text_page1 {
  color: #455470;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.text_page2 {
  color: #007AFF;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.text_page3 {
  color: #99A1AE;
  font-size: 14px;
  font-weight: 400;
}
