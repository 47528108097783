$border-radius: 8px;
$bg-white: #ffffff;
$color-white: #ffffff;
$color-black: #000000;
 //Header
$bg_header: #ffffff;
$bg_icons_search: #F4F6FF;
$bg_search: #F4F6FF;

//MenuNavigation
$bg-menu: #130e2f;
$active_link: rgba(255, 255, 255, 0.1);
$color-line_border-left: 2px solid #FB4400;
$color-text_link: #ffffff;
$bg-logout: #130E2F;

//Body
$bg-body: #F4F6FF !important;

//Active Inactive
$bg-activeOpen: #47CE6D;
$bg-inactiveOpen: #FFA800;
$bg-icons-inactive: #cee2ff;
$bg-icons-active: #007aff;
$width: 8px;

//Category icons text
$bg-color: rgba(54, 54, 54, 0.3);
$color-active: #007aff;
$color-sort: #73758C;
