.UpdateStarts {
  &__block {
    width: 496px;
    background-color: #ffffff;
    padding: 32px 24px 6px 24px;
    border-radius: 8px;
  }
  &__text {
    line-height: 1;
    padding-top: 14px;
    > h4 {
      font-size: 22px;
    }
    > p {
      font-weight: 400;
      color: #4C5B78;
      font-size: 14px;
    }
  }

  &__btn {
    display: flex;
    justify-content: right;
    padding-top: 30px;
  }

  .ant-col-offset-8 {
    margin-left: 0!important;
  }

  .ant-input {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
  }
}
