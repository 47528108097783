.Creating {
  &__create_promotion_form {
    border-radius: 8px;
    width: 512px;
  }

  .breadcrumbs {
    padding-bottom: 10px;
  }

  @media (max-width: 990px) {
    &__create_promotion_form {
      width: 100%;
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-input {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
  }

  padding: 32px;

  &__promotion-stage-one-style {
    width: calc(100%);
  }

  &__border-input-style {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
  }

  &__input-range-picker {
    display: flex;
    border-radius: 8px;
    border-color: #F4F6FF;
  }

  &__link-fields-styles {
    padding-left: 21px;
  }

  .input-text-form-style {
    margin-bottom: 6px;
  }

  .ant-picker-range {
    height: 34px !important;
  }
}

.ant-form-item-label {
  > label {
    font-weight: normal;
    color: #67697F;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FBBC05;
  border-color: #FBBC05;
  border-radius: 4px;
}
