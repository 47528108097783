.SkeletonBlock {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 16px;
    margin-top: 15px;
  }
  &__block_text_skeleton {
    width: 258px;
    height: 140px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
    padding: 12px;
  }
}

.title_skeleton {
  padding-right: 45px;
}

.ant-skeleton-element {
  width: 100% !important;
}
