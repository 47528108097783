.status_block_categories {
  display: flex;
  align-items: center;
  outline: none;
  border: 0;
  background: transparent;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 0;
  margin-top: -6px;
  margin-bottom: -6px;
  > .icons_categories_block {
    border-radius: 8px;
    padding: 0 2px 0.3px 2px;
  }

  > p {
    display: flex;
    padding-top: 12px;
    padding-left: 5px;
    color: $color-black;
    font-weight: 500;
    font-size: 11px;
  }
}

.status {
  text-align: center;
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    padding: 0;
  }

  > span {
    font-weight: 400;
    color: $color-sort;
    font-size: 14px;
  }
}

.Status__block_status {
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  border-bottom: 1px solid #F4F6FF;
  border-radius: 8px;
}

.ant-input-number-handler-wrap {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.black_category  {
  filter: brightness(0%);
}

