.Starts {
  &__title {
    display: flex;
    justify-content: space-between;
    > div {
      padding-top: 3px;
      display: flex;
    }
  }
  &__pagination_btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn_starts {
      border-radius: $border-radius;
      width: 129px;
    }
  }

  &__title_name {
    font-weight: 700;
  }
}
