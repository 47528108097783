.Info {
  &__ChallengesOpen_block {
    display: flex;
    gap: 20px;

    @media (max-width: 990px) {
      flex-direction: column;
    }

    &__card-actions {
      border-radius: $border-radius;
      width: 300px;
      height: 40%;
    }
  }

  &__table {
    padding-top: 40px;
    > p {
      font-weight: 500;
      font-size: 18px;
      color: #130E2F;
    }
  }
}
