.TableList {
  background-color: white;
  margin-top: 12px;
  border-radius: 8px;
  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 30px;
    > p {
      font-size: 14px;
      font-weight: 400;
      color: #121427;
      margin-top: 10px;
    }
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }

  .ant-btn, .ant-radio-group .ant-radio-button-wrapper {
    padding: 0 6px;
  }

  .ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td {
    padding: 10px 25px;;
  }
}

.ant-table.ant-table-small {
  font-size: 13px;
}

.bg_btn {
  color: red !important;
}

.block_text_table {
  display: flex;
  align-items: center;
  cursor: pointer;
  > span {
    padding-top: 8px;
    padding-left: 8px;
  }
}

.table_list_user {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
}

.table_list_popover {
  font-weight: 500;
  font-size: 14px;
}

.nexIcons {
  padding-bottom: 8px;
}
