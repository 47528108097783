.SkeletonUpdate {
  width: 496px;
  background-color: #ffffff;
  padding: 32px 24px;
  border-radius: 8px;

  &__group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 32px;
  }

  &__btn {
    display: flex;
    justify-content: right;
    > div {
      margin-left: 16px;
    }
  }
}
