.pagination {
  background-color: white;
  height:70px;
  display: flex;
  padding-top: 8px;

  &__pagination_show {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
    align-items: center;

    @media (max-width: 900px) {
      padding: 5px;
    }

    .glav_pagination {
      display: flex;
      .pagintion_str {
        display: flex;
        align-items: center;
        .str {
          font-size: 14px;
          color: #A5ADBC;
          font-weight: 400;
        }
        .text_input {
          padding-left: 10px;
          padding-right: 10px;
          input {
            width: 56px;
            height: 36px;
            padding-left: 12px;
            text-align: center;
            border: 1px solid #F1F1F1;
            border-radius: 4px;
          }
        }
      }

      .next_table {
        display: flex;
        align-items: center;
        padding-left: 10px;
        span {
          padding-right: 8px;
        }
      }
      .pagination_buttons {
        padding: 0 5px;
        border: none;
        border-radius: 4px;
        text-align: center;
        width: 36px;
        height: 36px;
        margin-right: 5px;
        line-height: 0.4;
      }
      .pagination_buttons-next {
        background: #007AFF;
      }
      .pagination_buttons-next:disabled {
        background: #F2F8FF;
      }
      .pagination_buttons-prev {
        background: #007AFF;
      }
      .pagination_buttons-prev > img {
        transform: rotate(180deg);
      }
      .pagination_buttons-prev:disabled {
        background: #F2F8FF;
      }
    }

    .pagination_total {
      display: flex;
      align-items: center;
      .text_span {
        color: #A5ADBC;
        font-size: 14px;
        padding-left: 16px;
      }
    }
  }
}