.MobileNavigation {
  display: none;
  position: fixed;
  top: 0;
  z-index: 7;
  width: 80px;
  height: 100vh;
  background-color: #130e2f;
  transition: width 1s;
  overflow: hidden;

  .active-links {
    color: rgba(255, 255, 255, 1);
  }

  @media (max-width: 820px) {
    display: block;
  }

  .menu_button {
    float: right;
    padding: 12px 20px;
    background: transparent;
    border: none;
  }

  .logo-open {
    width: 158px;
    height: 80px;
    margin: -6px -11px -16px;
    transition: all 1s;
  }

  &__logo_nomad {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > a > img {
      width: 60px;
      height: 60px;
      margin-left: 10px;
    }
  }

  &__navigation_menu {
    padding-top: 48px;
    padding-left: 20px;
    p {
      color: #aeb2d9;
      font-weight: 500;
    }
  }

  &__link_navigation {
    button {
      display: flex;
      padding-top: 10px;
      position: relative;
      cursor: pointer;
      div {
        img {
          width: 28px;
          height: 28px;
        }
      }
      p {
        height: 28px;
        display: flex;
        align-items: center;
        padding-left: 26px;
        color: rgba(255, 255, 255, 0);
        font-size: 14px;
        transition: color 1s;
      }
    }
    .activeLink {
      position: absolute;
      width: 56px;
      height: 39px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      padding-top: 25px;
      top: 4px;
      left: -8px;

      .line {
        width: 20px;
        height: 39px;
        position: absolute;
        top: 1px;
        border-left: 2px solid #FB4400;
        left: -10px;
      }
    }
  }

  &__logout {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    padding-top: 20px;
    padding-bottom: 29px;
    cursor: pointer;
    background-color: #130E2F;
    border-left: none;
    border-bottom: none;
    div {
      padding-left: 17px;
      img {
        width: 28px;
        height: 28px;
      }
    }
    p {
      height: 28px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      color: rgba(255, 255, 255, 0);
      font-size: 14px;
      transition: color 1s;
    }
  }
}

.active_menu_button {
  width: 220px;
}
.inactive_menu_button {
  width: 80px;
}