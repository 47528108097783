.SearchResults {
  width: 384px;
  &__title {
    font-size: 13px;
    font-weight: 400;
    color: #A4AED0;
  }

  .flex_block {
    display: flex;

    .text_icons_category {
      margin-top: 0;
    }
  }

  .line {
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #EEEEEE
  }

  .tag {
    font-weight: 400;
    font-size: 11px;
    color: #8B94A6;
    margin-bottom: -1px;
  }

  .promotions_card_top {
    padding-top: 20px;
  }

  .link_card {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    color: #007AFF;
    font-weight: 500;
  }
}

.not_text {
  font-weight: 400;
  font-size: 12px;
  color: #7A818E;
}

.btn_none_style_block_card {
  text-align: left;
  display: block;
  width: 100%;
  outline: none;
  border: 0;
  background: transparent;
}
