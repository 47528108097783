.Creating {
  width: 511px;
  background-color: #ffffff;
  padding: 32px 24px;
  border-radius: 8px;

  &__title {
    font-size: 22px;
    font-weight: 600;
    color: #212C41;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #ADBAD5;
    margin-top: -20px;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
  }

  .ant-input {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
  }

  &__text_tag {
    font-size: 12px;
    font-weight: 400;
    color: #949FB3;
    padding-top: 4px;
    margin-top: -25px;
    margin-bottom: 30px;
  }

  &__btn_form {
    display: flex;
    justify-content: right;
    padding-right: 44px;
    padding-top: 10px;
  }
}
