.ant-message-notice-content {
  padding: 0;
  border-radius: 8px;
}


.ant-message .anticon {
  display: none;
}

.errorPage {
  background-color: #FF4B55;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;

  .icons {
    padding-right: 10px;
  }
}

.successPage {
  background-color: #21d725;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;

  .icons {
    padding-right: 10px;
  }
}


.padding_bottom {
  padding-bottom: 10px;
}


.messageSuccessStyles {
  margin: 0;
}

.messageSuccessStyles div {
  border-radius: 8px;
  color: #fff;
  padding: 4px 61px;
}

.messageErrorStyles div {
  background: #FF4B55;
  border-radius: 8px;
  color: #fff;
  padding: 4px 20px;
}

.lockStyles {
  width: 21px;
  margin: 0 8px 5px 0;
}