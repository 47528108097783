.ant-select-dropdown {
  padding: 0 0;
}

.ant-picker-input > input {
  color: #130e2f;
  font-size: 14px;
  font-weight: 400;
}

.ant-select-selection-overflow {
  height: 46px;
  overflow: scroll;
}

.ant-select-selection-overflow::-webkit-scrollbar {
  width: 0;
}

.dFlex {
  display: flex;
  align-items: center;
}

.block_process_icons {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.block_padding {
  padding-left: 6px;
}

.ant-select-item-option {
  border-bottom: 1px solid #eff4ff;
  padding-top: 8px;
  padding-bottom: 6px;
}

.bg_input {
  border-radius: 8px;
  width: 100%;
  height: 40px;
  background-color: #F4F6FF;
  border: none;
}

.search_table {
  width: 100%;
  background-color: white;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;

  &__bg_select {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #f4f6ff;
      border: none;
      padding-top: 4px;
    }

    .ant-select-selector {
      height: 40px !important;
    }
  }
  &__search_input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    .block_flex_search {
      width: 100%;
    }

    .search_upper {
      display: flex;
      gap: 10px;
    }
  }

  &__export_file {
    width: 330px ;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #130E2F;
    background: #eaf2ff;
    font-weight: 600;
    border-radius: 8px;
    justify-content: center;
  }

  .ant-divider-horizontal {
    margin: 21px 0 15px;
  }

  &__date_table {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    align-items: center;

    > div {
      margin-left: 10px;
      .label_text {
        padding-bottom: 4px;
        font-size: 13px;
        > label {
          font-size: 13px;
          color: #67697f;
          font-weight: 400;
        }
      }
      .age_input {
        display: flex;
        align-items: center;
        background-color: #f4f6ff;
        width: 118px;
        height: 40px;
        border-radius: 8px;
        > div {
          width: 100%;
        }
        .ant-space-item {
          height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          top: 32%;
        }
      }
      .date_input {
        display: flex;
        align-items: center;
        background-color: #f4f6ff;
        width: 266px;
        height: 40px;
        border-radius: 8px;
        > div {
          width: 100%;
        }
        .ant-space-item {
          height: 32px;
        }
        .ant-select-multiple .ant-select-selection-placeholder {
          top: 32%;
        }
        .ant-select-clear {
          top: 34%;
        }
      }
    }
  }

  &__search_table_block {
    background-color: #fff;
    width: 422px;
    border-radius: 8px;
  }
}

.search_select {
  width: 100%;
  height: 72px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;

  .ant-input-affix-wrapper-lg {
    background-color: #f4f6ff !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background-color: #f4f6ff;
  }

  .border_form:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f4f6ff;
    border: none;
  }

  > div {
    margin-left: 12px;
    margin-top: 10px;
  }
}
