.Creating {
  &__option {
    background: #FBFBFF;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background: #F5FAFF;
    }

    img {
      height: 32px;
      border-radius: 50%;
      width: 32px;
    }
  }

  &__option_label {
    font-weight: 400;
    font-size: 11px;
    color: #8996A3;
    margin-bottom: 0;
  }

  &__option_info {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
  }

  &__autocomplete_label {
    display: inline-block;
    color: #67697F;
    font-size: 14px;
    font-weight: 400;
    font-family: -apple-system, sans-serif;
    margin-bottom: 10px;
  }

  &__target-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-weight: 400;
  }

  &__target-label {
    color: #67697F;
    font-size: 16px;
  }
}
