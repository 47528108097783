.Promotions {
  &__space-align-block {
    display: flex;
    flex-direction: column;
  }

  &__promotions-list-view {
    display: flex;
    flex-wrap: wrap;
  }

  &__promotions_card {
    @include widthHeightCard();
    border-radius: 8px;
    margin-right: 14px;
    margin-bottom: 17px;
    margin-left: 3px;
  }

  &__promotions_card .ant-card-body {
    padding: 0;
  }

  &__card_div {
    margin: 45px auto;
    text-align: center;
    > h5 {
      margin: 3px;
    }
  }

  &__card_custom_button {
    width: 40px;
    height: 40px;
    color: #000;
    line-height: normal;
    padding: 9px;
    background: rgba(0, 122, 255, 0.1);
    border: none;
    border-radius: 8px;
    margin-top: 28px;
  }

  &__buttons-promotions-styles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__promotions-buttons {
    border-radius: 8px;
    width: 129px;
    background: #007AFF;
  }
}

.left-button {
  position: absolute;
  bottom: 25px;
}

.right-button {
  position: absolute;
  right: 44px;
  bottom: 25px;
}

.block_promotions_title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}