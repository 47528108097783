.Update {
  padding: 13px 0;

  &__update-promotion-form-images {
    border-radius: 8px;
    margin-bottom: 20px;
    width: 496px;
    height: 100%;
  }

  @media (max-width: 990px) {
    &__update-promotion-form-images {
      width: 100%;
      height: 100%;
    }
  }

  &__create_promotion_form {
    width: 50%;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  @media (max-width: 990px) {
    &__create_promotion_form {
      width: 100%;
    }
  }

  &__border-input-style {
    border-radius: 8px;
    border-color: #F4F6FF;
  }

  &__input-range-picker {
    display: flex;
    border-radius: 8px;
    border-color: #F4F6FF;
  }

  &__link-fields-styles {
    padding-left: 21px;
  }

  .input-text-form-style {
    margin-bottom: 6px;
  }

  &__button-save {
    float: right;
    margin: 20px 0;
  }

  &__button-update {
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    width: 130px;
    height: 44px;
    background-color: rgba(0,122,255,.1);
  }

  &__button-update:hover {
    background-color: rgba(0,122,255,.1);
  }

  .button-cancel {
    margin-right: 10px;
    background-color: rgba(0, 122, 255, 0.1)
  }

  .button-save {
    background-color: #007AFF
  }
}

.ant-form-item-label {
  > label {
    font-weight: normal;
    color: #67697F;
  }
}

.button-save {
  margin-left: 20px;
}
