body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg-body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.btn_none_style {
  outline: none;
  border: 0;
  background: transparent;
}

.btn_block_style_none {
  display: block;
  text-align: left;
  outline: none;
  border: 0;
  background: transparent;
}

.btn_none_style_block {
  outline: none;
  border: 0;
  background: transparent;
}
.btn_none_style_block > div {
  text-align: left;
  margin-right: 0;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.text_disable {
  margin-bottom: -20px;
  color: red;
  font-size: 12px;
}

.title_card_hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_card_hidden {
  display: -webkit-box;
  max-width: 100%;
  height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_card_hidden_promotions {
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.show-more-button {
  padding: 0 5px 0 5px;
  height: 0;
}

///antd style

.ant-modal-content {
  padding: 0 !important;
}

.ant-message-notice-content {
  padding: 0 !important;
}

.ant-drawer-content {
  background-color: #f7f8ff !important;
}

.ant-popover-inner {
  padding: 0 !important;
}

.DrawerProfile .ant-input[disabled] {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
