.Update {
  &__participants_block {
    max-height: 300px;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  &__participant {
    width: 48%;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    font-size: 12px;
    background: #F5FAFF;
    padding: 11px 12px 10px 12px;
    border-radius: 8px;
  }

  &__participants_loading {
    height: 300px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__clipped-file {
    display: flex;
    gap: 5px;
    margin: 10px 0;
  }

  &__upload-file {
    .btn_file {
      cursor: pointer;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #130E2F;
      background: #eaf2ff;
      font-weight: 500;
      border-radius: 8px;
      justify-content: center;
      #upload-file {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }

  &__filename {
    color: #1677ff;
    font-size: 14px;
  }

  .invalid-file {
    color: #ff6a6b;
  }

  .invalid {
    background: #7f7f7f;
    color: white;
  }
}
