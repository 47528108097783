.SunEditorField {
  &__quill-styles {
    height: 240px;
    padding-bottom: 50px;
  }
  .ql-toolbar.ql-snow {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    border-bottom: transparent;
  }
  .ql-editor.ql-blank {
    > p {color: red;}
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
  }
}