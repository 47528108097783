.block_flex {
  display: flex;
  flex-wrap: wrap;
}

.static {
  margin-top: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  &__block_static {
    background-color: white;
    width: 274px;
    border-radius: 8px;
    padding: 20px;
    margin-right: 15px;
    > p {
      text-align: left;
      font-weight: inherit;
      color: #130E2F;
      line-height: 0.9;
      font-size: 14px;
    }

    .text_static {
      line-height: 0.8;

      > p {
        font-size: 11px;
        color: #130E2F;
        font-weight: 400;
        text-align: left;
      }

      .summa {
        display: flex;
        align-items: center;
        .text_sum {
          font-size: 18px;
          font-weight: 600;
        }

        .interest {
          font-size: 12px;
          color: #47CE6D;
          padding-left: 8px;
        }
        .sumRen {
          line-height: 0.2;
          padding-left: 24px;
          > p {
            color: #130E2F;
            font-weight: 200;
            font-size: 10px;
          }

          > div {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }

}