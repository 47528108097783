.CardComponent {
  &__card-styles {
    position: relative;
    @include widthHeightCard();
    border-radius: 8px;
    margin-right: 5px;
    margin-bottom: 17px;
    > .ant-card-head {
      position: absolute;
      border-bottom: none;
    }
  }

  &__card-styles .ant-card-body {
   @include card();
  }
}

.CardComponent__card-promotions {
  @include widthHeightCardPromotions()
}