.Mozilla_scroll {
  scrollbar-color: #007AFF #ffffff;
  scrollbar-width: thin;
  margin-top: -12px !important;
}

.Mozilla_button_end {
  bottom: -5px !important;
}

.Mozilla_block_scroll {
  height: 260px !important;
  scrollbar-width: none;
}
