.MenuNavigation {
  position: fixed;
  top: 0;
  z-index: 7;
  width: 260px;
  height: 100vh;
  background-color: $bg-menu;
  display: block;

  @media (max-width: 820px) {
    display: none;
  }

  &__logo_nomad {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    > img {
      width: 160px;
      height: 70px;
    }
  }

  &__navigation_menu {
    padding-top: 48px;
    padding-left: 32px;

    p {
      color: #aeb2d9;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__link_navigation {
    button {
      display: flex;
      padding-top: 10px;
      position: relative;
      cursor: pointer;

      div {
        img {
          width: 28px;
          height: 28px;
        }
      }

      p {
        height: 28px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: $color-text_link;
        font-size: 14px;
      }
    }

    .activeLink {
      position: absolute;
      width: 200px;
      height: 39px;
      background-color: $active_link;
      border-radius: 6px;
      padding-top: 25px;
      top: 4px;
      left: -8px;

      .line {
        width: 20px;
        height: 39px;
        position: absolute;
        top: 1px;
        border-left: $color-line_border-left;
        left: -24px;
      }
    }
  }

  &__logout {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    padding-top: 20px;
    cursor: pointer;
    background-color: $bg-logout;
    border-left: none;
    border-bottom: none;

    div {
      padding-left: 32px;

      img {
        width: 28px;
        height: 28px;
      }
    }

    p {
      height: 28px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      color: $color-text_link;
      font-size: 14px;
    }
  }
}
