.ant-image-img {
  border-radius: 6px;
}

.ant-image-mask-info{
  display: none;
}
.images_overflow {
  margin-left: 14px;
}

.ant-image-mask:hover {
  border-radius: 6px;
}


.CardInfo {
  width: 608px;
  height: 100%;
  background-color: $bg-white;
  padding: 24px;
  border-radius: $border-radius;

  &__title_card {
    font-size: 24px;
    font-weight: 600;
    color: #222936;
    padding-top: 12px;
    margin-bottom: 15px;
    word-break: break-word;
  }

  &__images_group {
    display: flex;
    justify-content: space-between;
    position: relative;
    .images_overflow {
      .block_scroll {
        height: 278px;
        margin-top: 5px;
        overflow-x: scroll;
        > div {
          margin-bottom: 12px;
        }
      }

      .block_scroll::-webkit-scrollbar {
        width: 0;
      }


      .block_start {
        width: 42px;
        height: 14px;
        background-color: #200E32;
        border-radius: 3px 3px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        right: 44px;
        top: -5px;
        cursor: pointer;
        border: none;
      }

      .block_end {
        width: 42px;
        height: 14px;
        background-color: #200E32;
        border-radius: 0 0 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        bottom: 8px;
        right: 44px;
        cursor: pointer;
        border: none;
      }
    }
  }

  &__status_run {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  &__challengesInfo_text {
    font-size: 14px;
    color: #374257;
    font-weight: 400;
    padding-top: 13px;
    word-break: break-word;
  }
}

.btn_show_more {
  outline: none;
  border: 0;
  background: transparent;
}
