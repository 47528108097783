.ant-table-thead > tr > th {
  width: 140px;
}

.container_block {
  width: 100%;
  padding-right: 10px;
}

.ant-input-affix-wrapper > input.ant-input {
  font-weight: 400 !important;
  padding-left: 5px !important;
}

.ant-radio-button-wrapper {
  padding: 0;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.card_photos {
  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 8px;
  }

  > span {
    padding-left: 10px;
  }
}

.table {
  background-color: #fff;
  width: 100%;
  &__table_navigation {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px 20px;

    p {
      font-weight: 400;
      font-size: 14px;
      padding-top: 5px;
    }
    .table_icons {
      display: flex;
      align-items: center;
      padding-top: 22px;
    }

    .icons_table_ {
      position: absolute;
      top: -0.6px;
      left: 5px;
    }

    .btn_card {
      width: 214px;
      height: 38px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      justify-content: space-between;
      background-color: #F7F8FF;
      border-radius: 8px;
      margin-bottom: 20px;

      > div {


        > button {
          width: 101px;
          height: 29px;
          font-size: 11px;
          font-weight: 400;
          color: black;
        }
        .active_card_button {
          background-color: #fff !important;
          color: #007AFF !important;
        }
      }
    }
  }

  .success .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: #47CE6D;
    border-radius: 4px;
  }

  .redBlock .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #CE4747 !important;
    border-radius: 4px !important;
  }

  .ant-select-arrow {
    color: white;
    font-weight: 600;
    padding-right: 8px;
  }

  .block_process {
    display: flex;
    justify-content: center;

    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: #fff;
      font-weight: 600;
      font-size: 11px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      color: #fff;
      font-weight: 600;
      font-size: 11px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
    div {
      select {
        outline: none;
        border: none;
        color: white;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  .table__table_th {
    overflow-y: scroll;
  }

  .table__table_th::-webkit-scrollbar {
    background-color: #fff;
    border-radius: 8px;
    width: 4px;
    height: 5px;
    overflow: hidden;
  }

  .table__table_th::-webkit-scrollbar-thumb {
    background-color: #007AFF;
    border-radius: 8px;
  }
}
