.DrawerProfile {
  &__avatar_block {
    display: flex;
    justify-content: center;
    position: relative;
    .avatar_photos {
      text-align: center;
      > img {
        width: 103px;
        height: 103px;
        border-radius: 8px;
      }

      > p {
        text-align: center;
        font-weight: 600;
        color: #121427;
        padding-top: 18px;
      }
    }

    .block_close {
      cursor: pointer;
      position: absolute;
      right: 0;
      border: none;
      background-color: #F7F8FF;
    }
  }

  &__form_input {
    background: $bg-white;
    border-radius: 8px;
    width: 100%;
    padding: 0 14px 14px 14px;
  }

  .ant-form-vertical .ant-form-item-label>label {
    font-size: 14px;
    color: #9C98CB;
    font-weight: 400;
    padding-top: 14px;
  }

  .ant-input[disabled] {
    color: #131629;
    font-weight: 400;
  }

  .ant-form-item {
    margin: 0;
  }
  }