.PageNotFound {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -60px;

  &__block_pageNotFound {
    width: 328px;
    display: flex;
    justify-content: center;
    > div {
      .title_PageNotFound {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        margin-top: -10px;
      }
      .text_PageNotFound {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #545D78;
        margin-top: -10px;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    > button {
      width: 149px;
      height: 40px;
      color: $color-white;
      font-weight: 600;
      font-size: 14px;
      border-radius: 8px;
    }
  }
}