.ErrorsNumber {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -90px;
  &__block_width {
    position: relative;
    width: 320px;
  }
  &__vector_1 {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  &__number_text {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    font-size: 86px;
    font-weight: 800;
    color: #007AFF;
    top: -60px;
  }

  &__flex_vector {
    position: relative;
    display: flex;
    justify-content: space-between;
    top: -134px;

    .vector_2 {
      position: relative;
      z-index: 4;
      left: 15px;
    }

    .vector_3 {
      position: relative;
      top: -10px;
    }
  }

  &__block_text {
    position: relative;
    top: -110px;

    > p {
      text-align: center;
    }

    .title_block {
      font-size: 20px;
      font-weight: 700;
      color: #130E2F;
    }

    .btn {
      display: flex;
      justify-content: center;
    }
  }
}