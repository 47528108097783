.SkeletonSearch {
  width: 384px;

  &__card {
    > div {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 20px;
    }
  }
}
