.Status {
  &__block_status {
    border: none;
    outline: none;
    background: transparent;
  }

  &__title {
    cursor: pointer;
    padding-left: 10px;
    font-weight: 600;
    font-size: 14px;
  }
}