.Update {
  width: 496px;
  background-color: $bg-white;
  padding: 32px 24px 24px 24px;
  border-radius: $border-radius;
  &__title {
    font-size: 22px;
    color: #212C41;
    font-weight: 600;
    padding-top: 5px;
  }

  &__text {
    font-size: 14px;
    color: #4C5B78;
    font-weight: 400;
    margin-top: -15px;
  }

  &__btn_edit {
    display: flex;
    justify-content: right;
  }

  &__text_tag {
    font-size: 12px;
    font-weight: 400;
    color: #949FB3;
    padding-top: 4px;
    margin-top: -25px;
    margin-bottom: 30px;
  }
}
