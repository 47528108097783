.modalEventsDelete {
  padding: 24px 72px 30px 55px;
  display: flex;
  justify-content: center;
  text-align: center;

  > div {
    h1 {
      font-weight: 700;
      font-size: 20px;
      color: #000;
      line-height: 1.3;
      margin-bottom: 23px;
    }

    .btn_delete {
      button {
        width: 148px;
        height: 44px;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

}
