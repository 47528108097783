.ModalActiveInactive {
  padding: 32px 72px 32px 55px;
  display: flex;
  justify-content: center;
  text-align: center;

  > div {
    h1 {
      font-weight: 700;
      font-size: 20px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #67697F;
      margin-top: -5px;
    }

    .btn_active_inactive {
      button {
        width: 140px !important;
        height: 44px;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

}
