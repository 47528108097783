.Info {
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__card {
    margin-right: 32px;
    margin-bottom: 32px;
  }

  &__block_btn {
    width: 346px;
    border-radius: 8px;
  }
}
