.TeamsInfo {
  &__header {
    display: flex;
  }

  &__info {
    background: #ffffff;
    width: 835px;
    border-radius: 16px;
    padding: 20px;
  }

  &__additional_info {
    display: flex;
    gap: 20px;
  }

  &__title {
    font-size: 20px;
    width: 485px;
    line-height: 31.2px;
    word-break: break-word;
  }

  &__date-info {
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 14px;
    color: #7893B0;

    span {
      color: #2F3137;
      font-weight: 400;
    }
  }

  &__buttons {
    margin: 0 20px;

     button {
       margin-bottom: 10px;
     }
  }

  &__tabs {
    margin: 20px 0;
  }

  &__tab {
    padding: 0 10px;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
  }
}
