.PhotosAdd {
  padding-top: 10px;
  position: relative;
  > img {
    width: 142px;
    height: 88px;
    border-radius: 8px;
  }

  > p {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.delete_icons_btn {
  position: relative;
  top: 0;
  outline: none;
  border: 0;
  width: 20px;
  height: 20px;
  background-color: #EB4335;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  > span {
    font-size: 12px;
  }
}
