.NoChallenges {
  width: 100%;
  height: 456px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;

  .text_NoChallenges {
    width: 285px;

    h1 {
      font-weight: 700;
      font-size: 28px;
      color: #130E2F;
      text-transform: initial;
      text-align: left;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #545D78;
    }
  }

  @media (max-width: 880px) {
    flex-direction: column;
    height: 511px;

    .text_NoChallenges {
      text-align: center;
    }
  }
}
