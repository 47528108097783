.Home {
  &__Last_updated {
    padding-top: 56px;

    .block_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > a {
        color: #007AFF;
        font-weight: 600;
        font-size: 14px;
        margin-top: -6px;
      }
    }

    .block_challenges_list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    color: #130E2F;
  }
}
