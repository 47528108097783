.SkeletonInfo {
  display: flex;
  &__fons {
    width: 608px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
  }

  &__group_images {
    margin-top: 20px;
    display: flex;
  }

  &__group_images_sk {
    margin-left: 16px;
  }

  &__text {
    margin-top: 30px;
  }

  &__btn {
    margin-left: 32px;
    width: 300px;
    height: 210px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;

    > div {
      margin-bottom: 12px;
    }
  }
}
