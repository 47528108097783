.block_form_percent {
  display: flex;
  justify-content: space-between;
}

.form_block {
  width: 512px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
  > p {
    font-weight: 400;
    font-size: 14px;
  }

  > div {
    cursor: pointer;
  }
}