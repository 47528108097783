.Creating {
  width: 512px;
  background-color: $bg-white;
  border-radius: $border-radius;
  padding: 32px 24px 24px 24px;
  &__title {
    font-size: 22px;
    font-weight: 600;
    color: #212C41;
    padding-top: 8px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #4C5B78;
    margin-top: -15px;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
  }

  .ant-input {
    font-size: 14px;
    color: #67697F;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
  }

  &__text_tag {
    font-size: 12px;
    font-weight: 400;
    color: #949FB3;
    padding-top: 4px;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  &__btn_form {
    display: flex;
    justify-content: right;
    padding-right: 44px;
    padding-top: 10px;
  }

  &__team {
    background: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    cursor: text;

    &:hover {
      border-color: #007aff;
    }

    &:focus {
      outline: none;
    }
  }

  &__hr-challenge {
    padding-left: 21px;
  }

  &__dropdown {
    background: #ffffff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  }

  &__search {
    margin-bottom: 10px;
    background: #F4F6FF;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    input {
      background: #F4F6FF;

      &:focus {
        border: none;
      }
    }
  }

  &__team_select {
    max-height: 230px;
    overflow: auto;
    margin-bottom: 10px;
  }

  &__team_option {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    margin-bottom: 8px;

    input {
      margin-top: 5px;
      outline: none;
      cursor: pointer;
    }

    label {
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      gap: 15px;
    }
  }

  &__team_name {
    color: #222936;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }

  &__team_organization {
    font-weight: 400;
    font-size: 13px;
    color: #767676;
    margin-bottom: 10px;
  }

  &__team_additional {
    display: flex;
    gap: 24px;
  }

  &__additional_subtitle {
    font-size: 11px;
    margin-bottom: 0;
    color: #767676;
  }

  &__additional_text {
    font-size: 11px;
    font-weight: 400;
    color: #222936;
    margin: 0;
  }

  &__not-found {
    font-size: 12px;
    color: #767676;
    text-align: center;
  }
}
