.ShowAllCard {
  display: flex;
  flex-wrap: wrap;

  &__file_loading {
    @include widthHeightCard();
    padding: 0 10px;
    border-radius: $border-radius;
    margin-right: 11px;
    margin-bottom: 17px;
    cursor: pointer;
    background-color: $bg-white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    margin-left: 6px;
    .block_loading {
      cursor: pointer;
      margin-top: 24px;
      > div {
        display: flex;
        justify-content: center;
        padding-bottom: 17px;
      }

      .create_file {
        > div {
          padding: 10px 11.5px 10px 11.5px;
          background-color: rgba(0, 122, 255, 0.1);
          border-radius: $border-radius;
        }
      }

      .block_text {
        text-align: center;
        cursor: pointer;
        > div {
          .title_card {
            font-weight: 600;
            font-size: 18px;
            color: #230b34;
            margin-bottom: 0;
          }

          .text_card {
            font-size: 12px;
            font-weight: 400;
            color: #939bc0;
          }
        }
      }
    }
  }

  &__file_loading:hover {
    -webkit-box-shadow: 3px -7px 42px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 3px -7px 42px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 3px -7px 42px 0px rgba(0, 0, 0, 0.12);
  }

  &__block_card {
    position: relative;
    .block_card_text {
      .card_title {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
        color: #130e2f;
      }

      .card_text {
        font-weight: 400;
        font-size: 11px;
        color: #8b94a6;
      }

      .block_run {
        width: 224px;
        overflow-x: scroll;
        display: flex;
        margin-top: 2px;
        .block_text {
          padding-right: 8px;
          .run_title {
            font-size: 9px;
            font-weight: 200;
            color: #a9aed1;
            margin-bottom: 0;
            margin-right: 10px;
            white-space: nowrap;
          }

          .run_text {
            font-size: 11px;
            font-weight: 400;
            color: #130e2f;
            white-space: nowrap;
          }
        }
      }

      .block_run::-webkit-scrollbar {
        width: 12px;
        height: 4px;
      }

      .block_run::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 2px;
      }

      .block_run::-webkit-scrollbar-thumb {
        -webkit-border-radius: 2px;
        border-radius: 2px;
        background: #007aff;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
      .block_run::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 0, 0, 0.4);
      }

      .block_card {
        height: 68px;
      }
    }

    .status_card {
      position: absolute;
      top: 10px;
      left: 15px;
      display: flex;
      z-index: 1;
      align-items: center;
    }
    .status_cardBlock {
      background-color: $bg-color;
      width: 62px;
      height: 34px;
      border-radius: $border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
    }
  }

  &__block_category_active {
    padding-left: 8px;
  }

  &__team_indicator {
    margin: 0 8px;
    background: rgba(black, 0.5);
    border-radius: 5px;
  }
}

.content_popover {
  font-weight: 500;
  font-size: 14px;
}
.text_icons_category {
  width: 32px;
  position: relative;
  top: 4px;
  left: 1px;
  font-size: 11px;
  color: $color-white;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover-inner-content {
  padding: 8px 18px !important;
}

.flex_block {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: white;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  .black_category {
    filter: brightness(0) saturate(100%) invert(37%) sepia(46%) saturate(4849%) hue-rotate(197deg)
      brightness(97%) contrast(115%);
  }
  .status_block_categories > p {
    color: $color-active;
  }

  .icons_categories_block > span > svg {
    color: $color-active;
  }
}
.ant-card-cover img {
  border-radius: 8px 8px 0 0;
}

.d-block {
  display: block !important;
}

.fileLoadingPromotions {
  @include widthHeightCardPromotions()
}
