.SkeletonCategory {
  &__block {
    width: 511px;
    background-color: #ffffff;
    padding: 32px 24px;
    border-radius: 8px;
    .margin_bottom {
      margin-bottom: 20px;
    }
  }

  &__block_2 {
    margin-top: 16px;
  }

  &__block_3 {
    width: 511px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    margin-top: 32px;
  }

  &__block_4 {
    width: 288px;
    background-color: #ffffff;
    margin-left: 32px;
    border-radius: 8px;
    padding: 24px;

    .progress_text {
      padding-top: 30px;
    }
  }
}

.SkeletonCategory_sections {
  display: flex;
}
