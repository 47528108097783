.Header {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 78px;
  background-color: $bg_header;
  display: flex;
  align-items: center;
  padding-left: 292px;

  @media (max-width: 820px) {
    padding-left: 100px;
  }

  .select_lan:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $bg_icons_search;
    border-radius: 8px;
  }

  .select_lan:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 40px;
    padding-top: 4px;
  }

  .search_header {
    background-color: $bg_search;
    border-radius: 8px;

    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 38px;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      padding-top: 8px;
    }
  }

  .text_lan_option {
    margin-top: -1px;
  }

  .search_icons {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  .text_lan_option {
    margin-top: -1px;
  }
  &__search_block {
    display: flex;
    position: relative;
    margin-right: 12px;
    .img_search {
      width: 24px;
      height: 24px;
      position: absolute;
      z-index: 2;
      margin-top: 8px;
      margin-left: 10px;
      background-color: $bg_icons_search;
      border: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $bg_search;
      border: none;
      height: 36px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      padding-left: 26px;
      padding-top: 3px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      padding-left: 26px;
      padding-top: 7px;
    }
  }

  &__update_cache_button {
    position: absolute;
    right: 0;
    padding: 10px;
    margin-right: 24px;
  }
}

.block_search {
  > p {
    font-size: 14px;
    font-weight: 600;
    color: #130E2F;
    margin-bottom: 3px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    color: #7A818E;
  }
}

.width_block_search {
  background-color: $bg-color;
  height: 32px;
  width: 66px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 3px 0 0 6px;
  > div {
    margin-top: -1px;
  }
}

.ant-select-dropdown {
  padding: 0 0;
}

.block_search_results {
  text-align: center;
  color: #007AFF;
  font-weight: 600;
  font-size: 12px;
}

.rc-virtual-list-holder {
  min-height: 100%;
}
.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.text_lang {
  position: relative;
  top: -8px;
  left: 32.3px;
  font-size: 10px;
  color: black;
  font-weight: 200;
  margin-bottom: -10px;
}

.text_lan_option {
  font-size: 13.5px;
}

.clear_search {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
}
