@font-face {
  font-family: tacticSans;
  src: url(fonts/TacticSans-Med.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: tacticSans;
  src: url(fonts/TacticSans-Bld.ttf);
  font-weight: 700;
}

.body {
  max-width: 1920px;
}

.main {
  background-color: #1D1D1B !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'tacticSans', sans-serif;
  color: #fff;
  overflow-x: hidden;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
}

header {
  max-width: 1920px;
  height: 80px;
  background-color: #000;
  display: flex;
  justify-content: space-between;

}

.logo {
  width: 250px;
  height: 80px;
}

.navbar {
  width: 800px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_items {
  list-style-type: none;
}

.navbar_items a {
  text-decoration: none;
  color: #fff;
}

.navbar_items a:hover {
  color: #FF4E00;
}

.promo {
  position: relative;
  width: 100%;
  height: 900px;
}

.arrow1 {
  position: absolute;
  left: 700px;
}

.arrow2 {
  position: absolute;
  top: 180px;
  left: 430px;
}

.main-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 100px;
}

.main-content_right {
  width: 800px;
  margin-top: 90px;
}

.main-page-title {
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 85%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0;
}

.google {
  width: 200px;
  height: 60px;
  margin-top: 60px;
  margin-left: 110px;
}

.apple {
  width: 200px;
  height: 60px;
  margin-top: 60px;
  margin-left: 120px;
}

@media all and (max-width: 500px) {
  .underline {
    display: none;
  }
  .main-content {
    padding-top: 0;
  }
  .main-content_right {
    width: 100%;
    margin-left: 15%;
    margin-top: 26px;
    position: absolute;
    z-index: 12000;
  }
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
  }
  .promo {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .arrow4 {
    z-index: 1;
  }
  .main-content_left {
    display: none;
  }
  .main-page-title {
    width: 239px;
    height: 113px;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 75%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .google {
    width: 57.9px;
    height: 18px;
    margin-top: 0;
    margin-left: 40px;
  }
  .apple {
    width: 57.9px;
    height: 18px;
    margin-top: 0;
    margin-left: 40px;
  }

}

.orange {
  color: #FF4E00;
}

.underline {
  width: auto;
  height: 1px;
  background-color: #fff;
}

.about_app {
  width: 100%;
  height: 900px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}

.about_app-content {
  position: relative;
  display: flex;
  align-items: center;
}

.about_app-info {
  width: 620px;
  height: 486px;
}

.about_app-info p {
  margin: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
}

.about_app_img {
  margin-top: -35px;
}

.swim_icon {
  position: absolute;
  left: 0;
  top: 650px;
  opacity: 0.2;
}

.run_icon {
  position: absolute;
  left: 900px;
  top: 650px;
  opacity: 0.2;
}

.bike_icon {
  position: absolute;
  left: 450px;
  top: 650px;
  opacity: 0.2;
}

@media all and (max-width: 500px) {
  .about_app {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .about_app-content {
    margin: 0 10px;
    display: flex;
    position: relative;
    width: 80%;
  }
  .about_app-info {
    width: 100%;
    height: 276px;
    position: absolute;
    z-index: 12;
  }
  .about_app-info p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
  }
  .about_app_img {
    position: absolute;
    z-index: 7;
  }
  .about_app_img img {
    position: absolute;
    width: 189px;
    height: 220px;
    left: 50px;
    top: -121px;
    z-index: -1;
    filter: brightness(40%);
    display: block;
  }
  .swim_icon {
    display: none;
  }
  .run_icon {
    display: none;
  }
  .bike_icon {
    display: none;
  }
}

.about_us {
  width: 100%;
  height: 900px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_us_content {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: space-evenly;
}

.about_us-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.counters {
  width: 260px;
  height: 120px;
  background: #FF4E00;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.counters p {
  margin: 0;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #FFFEFE;
}

.about_us-right {
  width: 566px;
  height: 486px;
  margin-top: 70px;

}

.about_us-right p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
}

.arrow3 {
  position: absolute;
  left: 406px;
  top: 20px;
}

.arrow4 {
  position: absolute;
  left: 140px;
  top: 198px;
  z-index: -1;
}

@media all and (max-width: 500px) {
  .about_us {
    width: 100%;
    height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about_us_content {
    margin-top: 0;
    width: 80%;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .about_us-left {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  .counters {
    width: 74px;
    height: 34px;
    background: #FF4E00;
    border-radius: 50px;
  }
  .counters p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #FFFEFE;
  }
  .about_us-right {
    width: 100%;
    margin: 0;

  }
  .about_us-right p {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
  }
  .arrow3 {
    width: 200px;
    height: 158px;
    left: 30%;
    top: 0;
  }
  .arrow4 {
    width: 200px;
    height: 158px;
    left: 11%;
    top: 40px;
    z-index: 1;
  }
}

.challenge {
  max-width: 1920px;
  height: 900px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

}

.challenge_block {
  width: 100%;
  height: 900px;
  position: relative;
}

.challenge_block h2 {
  display: inline-block;
  margin-left: 464px;
  margin-top: 75px;
}

.challenge_block_text {
  margin-top: 0;
  width: 566px;
  height: 486px;
}

.challenge_block_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
}

.challenge_block_img img {
  position: absolute;
  left: 500px;
  top: 72px;
}

@media all and (max-width: 500px) {
  .challenge {
    width: 100%;
    height: 380px;
    background-image: none;
    background-repeat: no-repeat;
    display: flex;
  }
  .challenge_block {
    margin-top: 10px;
    width: 100%;
    height: 307px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .challenge_block {

  }
  .challenge_block h2 {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0 auto;
  }
  .challenge_block_text {
    margin-top: 0;
    width: 80%;
  }
  .challenge_block_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
  }
  .challenge_block_img img {
    display: none;
  }

}

#install_and_dowmload {
  margin-bottom: 50px;
}

.install {
  position: relative;
  /* margin-left: 220px; */
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.install_descr {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.install_descr_text {
  position: relative;
  width: 566px;
  height: 486px;
  margin-top: 90px;
}

.install_descr_video {
  width: 560px;
  height: 350px;
  position: relative;
  overflow: hidden;
}

.youtube_screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.apple_small {
  width: 120px;
  height: 40px;
  position: absolute;
  top: 63px;
  left: 220px;
}

.google_small {
  width: 120px;
  height: 40px;
  position: absolute;
  top: 63px;
  left: 70px;
}

.install_descr_text p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
}

.install_descr_video {
  margin-top: 70px;
}

@media all and (max-width: 500px) {
  .install {
    position: relative;
    width: 100%;
    height: 326px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .install_descr {
    width: 80%;
    height: 400px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .install_descr_text {
    position: relative;
    width: 100%;
    height: 130px;
    margin-top: 10px;
  }
  .apple_small {
    display: none;
  }
  .google_small {
    display: none;
  }
  .install_descr_text p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
  }
  .install_descr_video {
    width: 100%;
    height: 150px;
    margin-top: 35px;
  }
  #install_and_dowmload {
    margin-bottom: 0;
  }
}

.partners_block {
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.partners_block_text {
  margin-top: 20px;
  margin-bottom: 100px;
  width: 80%;
  height: 515px;
  left: 300px;
  top: 159px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
}

.partners_block_button {
  width: 298px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF4E00;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.btn {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
}

.partners_block_button:hover {
  box-shadow: 0 12px 16px 0 rgba(236, 233, 233, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  width: 299px;
  height: 51px;
}

@media all and (max-width: 500px) {
  .partners_block {
    width: 100%;
    height: 560px;
  }
  .partners_block_text {
    margin-top: 0;
    margin-bottom: 20px;
    width: 80%;
    height: 400px;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
    color: #FFFFFF;
  }
  .btn {
    font-size: 12px;
    line-height: 14px;
  }
  .partners_block_button {
    width: 132px;
    height: 35px;
  }
  .partners_block_button:hover {
    box-shadow: 0 12px 16px 0 rgba(236, 233, 233, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    width: 133px;
    height: 36px;
  }
}

footer {
  max-width: 1920px;
  height: 443px;
  background-color: rgb(0, 0, 0);
  margin: 0 auto;
}

.footer_block {
  width: 1160px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.footer_menu {
  list-style-type: none;
  margin-top: 24px;
}

.footer_menu li {
  margin-top: 12px;
}

.footer_menu_items a {
  text-decoration: none;
  color: #fff;
}

.footer_menu_items a:hover {
  color: #FF4E00;
}

.phone_number {
  width: 220px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email {
  margin-top: 19px;
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    margin: 0 0 0 19px;
  }
}

.support {
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    margin: 0 0 0 25px;
  }
}

.footer_contacts h2 {
  margin-top: 40px;
  margin-bottom: 29px;
  width: 216px;
  height: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #FFFFFF;
  text-transform: uppercase;
}

.footer_support {
  margin: 4px 0 0 0;
}

.footer_social {
  height: 140px;
}

.footer_social h2 {
  margin-top: 45px;
  margin-bottom: 40px;
  width: 216px;
  height: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #FFFFFF;
  text-transform: uppercase;
}

.social_icons {
  padding-top: 0;
  margin-top: 10px;
  width: 26px;
  height: 26px;
  background: rgba(196, 196, 196, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.social_icons:hover {
  background-color: #FF4E00;
}

.social_icons:hover + .social_name p {
  color: #FF4E00;
}

.footer_store {
  height: 48px;
  width: 162px;
  margin-right: 25px;
}

.footer_block_store {
  margin-left: 537px;
  margin-top: 50px;
}

.footer_social_list {
  width: 200px;
  display: flex;
}

.social_name {
  margin-left: 10px;
}

.burger_menu {
  visibility: hidden;
  width: 18px;
  height: 10px;
  margin-top: 12px;
  margin-right: 0;
}

@media all and (max-width: 500px) {
  body {
    width: 100%;
  }
  .partners_block img {
    display: none;
  }
  .about_us img {
    display: none;
  }
  header {
    width: 400px;
    height: 34px;
    display: flex;
    justify-content: space-between;
  }
  .navbar {
    display: none;
  }
  .logo {
    margin-left: 0;
    width: 280px;
  }
  .logo img {
    width: 110px;
    height: 34px;
  }
  .burger_menu {
    visibility: visible;
  }
  footer {
    width: 400px;
    height: 217px;
    padding-top: 10px;
    position: relative;
  }
  .footer_block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  .footer_menu {
    padding-left: 20px;
  }
  .footer_navigation {
    width: 50%;
  }
  .footer_logo img {
    width: 110px;
    height: 34px;
  }
  .footer_menu {
    list-style-type: none;
    margin-top: 10px;
  }
  .footer_menu li {
    margin-top: 4px;
    font-size: 10px;
  }
  .footer_contacts {
    width: 50%;
    margin: 0 auto;
  }
  .phone_number {
    position: relative;
    margin-top: 4px;
    width: 120px;
    height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
  }
  .phone_number img {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -2px;
    left: -14px;
  }
  .email {
    position: relative;
    margin-top: 4px;
    width: 135px;
    height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    > p {
      margin: 0 0 7px;
    }
  }
  .email img {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -2px;
    left: -14px;
  }
  .support {
    position: relative;
    margin-top: 4px;
    width: 135px;
    height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    > p {
      margin: 0 0 7px;
    }
  }
  .support img {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -2px;
    left: -14px;
  }
  .footer_social {
    width: 100%;
    height: 26px;
    display: flex;
    justify-content: space-evenly;
  }
  .footer_contacts h2 {
    margin-top: 45px;
    margin-bottom: 10px;
    width: 115px;
    height: 14px;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #FFFFFF;
    text-transform: uppercase;
  }
  .footer_support {
    margin: 5px 0 0 0 !important;
  }
  .footer_social h2, .social_name {
    display: none;
  }
  .footer_social_list {
    justify-content: center;
  }
  .footer_store {
    width: 73px;
    height: 21px;
  }
  .footer_block_store {
    margin: 0;
    position: absolute;
    width: 157px;
    display: flex;
    left: 170px;
    top: 13px;
  }

}
